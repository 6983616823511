<template>
  <div class="group/ccc-member-badge relative">
    <div
      class="larken grid h-full w-[70px] cursor-pointer grid-rows-[1fr_auto] overflow-hidden rounded-2xl bg-[url('/images/dorito.png')] bg-contain bg-center max-md:w-14"
      :class="isCCMember ? 'bg-[#182019]' : 'bg-[#191919]'"
    >
      <div
        class="col-start-1 row-start-2 whitespace-nowrap p-1 text-center text-[10px] leading-none"
        :class="isCCMember ? 'bg-[#21472e] text-[#3bf992]' : 'bg-[#423819] text-[#ECBA33]'"
      >
        {{ isCCMember ? 'Club Member' : '$DORITO' }}
      </div>
    </div>
    <div
      v-if="isCCMember"
      class="top-100% absolute right-1/2 z-[1] hidden translate-x-1/2 pt-2.5 md:group-hover/ccc-member-badge:flex"
    >
      <GlobalDoritoCard class="w-max" />
      <!-- <div
        class="grid w-64 rounded-2xl bg-[#141414] p-1"
        style="
          box-shadow:
            0px 966px 270px 0px rgba(102, 75, 0, 0),
            0px 618px 247px 0px rgba(102, 75, 0, 0.02),
            0px 348px 209px 0px rgba(102, 75, 0, 0.08),
            0px 155px 155px 0px rgba(102, 75, 0, 0.14),
            0px 39px 85px 0px rgba(102, 75, 0, 0.16),
            0px 4px 50px 0px rgba(0, 0, 0, 0.25);
        "
      >
        <GlobalDoritoCard />
        <img
          class="col-start-1 row-start-1 w-full rounded-t-[14px]"
          src="/images/ccc-member-panel-bg.png"
          alt=""
        />
        <div
          class="relative col-start-1 row-start-1 flex flex-col gap-2.5 overflow-hidden rounded-[14px] bg-gradient-to-b to-[#ECBA3300] p-4 pt-12"
          :class="isCCMember ? 'from-[#33FF991A]' : 'from-[#ECBA331A]'"
        >
          <div class="flex items-center gap-2.5 whitespace-nowrap text-[#ECBA33]">
            <template v-if="isCCMember">
              <span class="text-xs text-[#33FD98]">15021.92</span>
              <span class="text-xs text-[#EAB933]">+ 327.81</span>
              <span class="text-xs text-white">$DORITO</span>
            </template>
            <template v-else>
              <span class="larken"> Join Crypto's Country Club </span>
              <Icon class="min-w-[1em] text-sm" icon="mage:arrow-right" />
            </template>
          </div>
          <span class="text-xs leading-tight text-white/50">
            <template v-if="isCCMember">
              You are a CCC member, enjoy the perks!
              <router-link
                to="/tutorial/ccc"
                class="inline-block cursor-pointer align-text-bottom hover:text-[#ECBA33]"
              >
                <Icon icon="fluent:info-12-regular" />
              </router-link>
            </template>
            <template v-else>
              Own 0.01% or more of the $DORITO supply to become a member of the Crypto's Country
              Club
            </template>
          </span>
          <div class="absolute bottom-0 left-0 flex h-1 w-full bg-[#ECBA33]">
            <div
              class="h-full border-r border-solid border-[#141414] bg-[#33FF99]"
              style="width: 80%"
            />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
// import { Icon } from '@iconify/vue/dist/iconify.js'
import { storeToRefs } from 'pinia'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'

const { isCCMember } = storeToRefs(useSwapkitWalletStore())
</script>
